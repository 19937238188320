/* LoginScreen.css - NavisX Login UI - Dark Mode, 2025 UX Best Practices */

/* Make entire app non-scrollable and occupy full height */
html, body, #root {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;
    touch-action: manipulation; /* optional: improves UX for touch inputs */
  }

body {
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: none;
  }
  

:root {
    --background-color: #141414;
    --surface-color: #1f1f1f;
    --primary-color: #9a4dff;
    --primary-hover-color: #7b34e0;
    --text-color: #e0e0e0;
    --text-muted-color: #9e9e9e;
    --border-color: #2c2c2c;
    --button-text-color: #ffffff;
    --accent-color: #03dac5;
  }
  
  .login-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: var(--background-color);
    color: var(--text-color);
    padding: 3rem 1rem;
    gap: 1rem;
    text-align: center;
  }
  
  .login-logo {
    width: 120px;
    height: 120px;
    margin-bottom: 2rem;
  }
  
  .login-screen h2 {
    font-size: 2.8rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  .login-screen h2::after {
    content: "Early Access / สิทธิ์เข้าถึงก่อนใคร";
    display: block;
    font-size: 1rem;
    color: var(--accent-color);
    margin-top: 0.5rem;
  }
  
  .login-screen p {
    font-size: 1.4rem;
    line-height: 1.6;
    margin-bottom: 2.5rem;
    color: var(--text-muted-color);
  }
  
  

  .login-screen input::placeholder {
    color: var(--text-muted-color);
  }
  
  .login-screen > button {
    /* login button only */
    background-color: var(--primary-color);
    color: var(--button-text-color);
    border: none;
    padding: 1.2rem 2.5rem;
    font-size: 1.3rem;
    font-weight: 500;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  
  .login-screen > button:hover {
    background-color: var(--primary-hover-color);
  }  
    
  /* Loading animation section */
  .login-loader {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--text-muted-color);
    font-size: 1.1rem;
  }
  
  .dot-flashing {
    margin-bottom: 0.5rem;
    position: relative;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: var(--primary-color);
    animation: dotFlashing 1s infinite linear alternate;
  }
  
  @keyframes dotFlashing {
    0% {
      background-color: var(--primary-color);
    }
    50%,
    100% {
      background-color: #5f2aa0;
    }
  }


.password-input-container {
    position: relative;
    max-width: 500px;
    width: 100%;
    margin: 0 auto 2rem;
  }
  
  .password-input-container input {
    display: block;
    width: 90%;
    font-size: 1.4rem;
    height: 3.6rem;
    line-height: 3.6rem;
    border-radius: 8px;
    border: 1px solid var(--border-color);
    background-color: var(--surface-color);
    color: var(--text-color);
    padding: 0 2.5rem 0 1rem;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.05);
    text-align: center;
  }
  
  .password-input-container .eye-button {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--text-muted-color);
  }
  
  .password-input-container .eye-button:hover {
    color: var(--text-color);
  }
  
  .password-input-container .eye-button svg {
    width: 1.4rem;
    height: 1.4rem;
  }
  


/* Add this to your LoginScreen.css file */
@media (max-height: 700px) {
    .login-screen {
      justify-content: flex-start;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      height: auto;
      min-height: 100vh;
      overflow-y: auto;
    }
    
    .login-logo {
      width: 80px;
      height: 80px;
      margin-bottom: 1rem;
    }
    
    .login-screen h2 {
      margin-bottom: 0.5rem;
    }
    
    .login-screen p {
      margin-bottom: 1.5rem;
    }
    
    .password-input-container {
      margin-bottom: 1.5rem;
    }
  }