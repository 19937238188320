/* InvalidPassword.css - Dark Mode Error Screen, Modern UX */

:root {
    --background-color: #141414;
    --surface-color: #1f1f1f;
    --primary-color: #9a4dff;
    --primary-hover-color: #7b34e0;
    --text-color: #e0e0e0;
    --text-muted-color: #9e9e9e;
    --border-color: #2c2c2c;
    --button-text-color: #ffffff;
    --accent-color: #ff4d4d;
  }
  
  .invalid-password {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: var(--background-color);
    color: var(--text-color);
    padding: 2rem;
    text-align: center;
  }
  
  .invalid-password h2 {
    font-size: 2rem;
    color: var(--accent-color);
    margin-bottom: 1rem;
  }
  
  .invalid-password p {
    font-size: 1.2rem;
    color: var(--text-muted-color);
    margin-bottom: 2rem;
  }
  
  .invalid-password button {
    background-color: var(--primary-color);
    color: var(--button-text-color);
    border: none;
    padding: 1rem 2rem;
    font-size: 1.1rem;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .invalid-password button:hover {
    background-color: var(--primary-hover-color);
  }
  
  @media (max-width: 600px) {
    .invalid-password h2 {
      font-size: 1.5rem;
    }
  
    .invalid-password p {
      font-size: 1rem;
    }
  
    .invalid-password button {
      width: 100%;
      max-width: 300px;
    }
  }
  