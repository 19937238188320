/* SalesReport.css - Modern Dark Mode with Sticky Header, Subheader, Pagination, Filter Search, and improved contrast */

:root {
  /* Slightly brighter backgrounds for better contrast on large/bright monitors */
  --background-color: #141414;
  --surface-color: #1f1f1f;
  
  --primary-color: #9a4dff;
  --primary-hover-color: #7b34e0;
  --text-color: #e0e0e0;
  --text-muted-color: #9e9e9e;
  --border-color: #2c2c2c;
  --accent-color: #03dac5;
  --button-text-color: #ffffff;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  padding: 0;
}

.container {
  position: relative;
  padding-top: 180px; /* adjust if needed */
  padding-left: 1rem;
  padding-right: 1rem;
}

/* Main fixed header for logo and NavisX branding */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  /* Darker than surface color to differentiate header area */
  background-color: #1c1c1c;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  z-index: 1000;
}

.logo img {
  height: 40px;
  margin-right: 1rem;
}

/* Reduced font size for brand name */
.header h1 {
  margin: 0;
  font-size: 1.5rem; /* was 1.8rem */
  color: var(--text-color);
}

/* Subheader for report title, search bar, and refresh button */
.subheader {
  position: fixed;
  top: 80px; /* same as header height */
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1.5rem;
  background-color: #242424;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 999;
}



/* Slightly smaller title for subheader */
.subheader h2 {
  margin: 0;
  font-size: 1.3rem;
  color: var(--text-color);
  white-space: normal;       /* ✅ allow line breaks */
  word-break: break-word;    /* ✅ break if word too long */
  overflow-wrap: break-word; /* ✅ for better browser support */
  flex-shrink: 1;            /* ✅ allow title to shrink with the layout */
}

.subheader-actions {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex: 1;
}


/* Search bar */
.subheader-actions input {
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background-color: var(--surface-color);
  color: var(--text-color);
  flex-grow: 1;
  min-width: 0;
}

/* Content area */
.content {
  padding: 2rem;
}

.actions {
  display: flex;
  gap: 1rem;
}

.primary-button {
  background-color: var(--primary-color);
  border: 1px solid #ffffff20;
  border-radius: 6px;
  padding: 0.6rem 1.2rem;
  font-size: 1rem;
  color: var(--button-text-color);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
}

.primary-button:hover {
  background-color: var(--primary-hover-color);
}

.table-container {
  flex: 1;
  overflow-y: auto;
  overflow-x: auto; /* keep for horizontal scroll on small screens */
  max-height: calc(100vh - 300px); /* 180px padding-top + some space for actions & pagination */
  margin-bottom: 1rem;
  -webkit-overflow-scrolling: touch;
}

.table-container::after {
  width: 15px;
  background: linear-gradient(to left, rgba(20, 20, 20, 0.7), rgba(20, 20, 20, 0));
}


.data-table {
  width: 100%;
  border-collapse: collapse;
  min-width: 1000px;
  color: var(--text-color);
}

/* Lighten the table header background for better contrast */
th {
  background-color: #2f2f2f; /* was #272727 */
  color: #ffffff;
  text-align: left;
  padding: 12px;
  position: sticky;
  top: 0;
  z-index: 2;
  font-size: 0.95rem;
}

td {
  padding: 0.75rem 1rem;
  font-size: 0.95rem;
  border-bottom: 1px solid var(--border-color);
  text-align: left;
  vertical-align: top;
}

tr.group-row {
  background-color: #1a1a1a;
  font-weight: bold;
}

/* detail-row background remains var(--surface-color) */
.detail-row {
  background-color: var(--surface-color);
  font-style: normal;
  animation: fadeInSlide 0.4s ease;
  color: var(--text-color);
}

/* Hover row color is slightly lighter for better visibility on bright monitors */
.detail-row:hover {
  background-color: #333333; /* was #2a2a2a */
}

.detail-row em {
  color: var(--text-muted-color);
  font-style: italic;
}

.detail-row strong {
  color: var(--accent-color);
}

@keyframes fadeInSlide {
  from {
    opacity: 0;
    transform: translateY(-4px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Even row shading for improved contrast */
.data-table tbody tr:nth-child(even):not(.detail-row) {
  background-color: #262626; /* was #181818 */
}

/* Hover color is slightly brighter */
tr:hover:not(.detail-row) {
  background-color: #333333; /* was #2a2a2a */
}

.toggle-button {
  background: none;
  border: none;
  color: var(--accent-color);
  font-size: 1.1rem;
  cursor: pointer;
  padding: 0;
  transition: color 0.2s ease-in-out;
}

.toggle-button:hover {
  text-decoration: underline;
  color: #00ffe1;
}

td:last-child {
  white-space: nowrap;
}

.actions.right {
  justify-content: flex-end;
  margin-top: 2rem;
}

/* Pagination styles */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  gap: 1rem;
}

.pagination button {
  background-color: var(--primary-color);
  border: 1px solid #ffffff20;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  color: var(--button-text-color);
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.pagination button:disabled {
  background-color: #555;
  cursor: not-allowed;
}

.pagination span {
  font-size: 1rem;
  color: var(--text-color);
}

/* Loading overlay styling */
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: var(--text-color);
  border-radius: 8px;
  z-index: 50;
}


@media (max-width: 768px) {
  .actions {
    flex-direction: column;
    align-items: stretch;
    gap: 0.5rem;
  }

  .actions.right {
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }

  .primary-button {
    width: 100%;
    text-align: center;
    font-size: 1rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  .data-table th,
  .data-table td {
    font-size: 0.85rem;
    padding: 0.5rem;
  }
}


/* Force follow-up column to wrap */
.follow-up {
  white-space: normal;
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

/* Narrow max width for follow-up column on mobile */
@media (max-width: 768px) {
  .data-table td.follow-up,
  .data-table th.follow-up {
    white-space: normal !important;
    word-break: break-word;
    overflow-wrap: break-word;
    word-wrap: break-word;
    max-width: 200px;
    min-width: 160px; /* ← try 120–160px and adjust */
  }
}



/* Mobile-friendly stacked layout for subheader */
@media (max-width: 768px) {
  .subheader {
    flex-direction: column;
    align-items: stretch;
    gap: 0.75rem;
  }

  .subheader h2 {
    text-align: center;
    font-size: 1.2rem;
    white-space: normal;
    word-break: keep-all;
  }

  .subheader-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    gap: 0.5rem;
  }

  .subheader-actions input {
    flex: 1;
    min-width: 0;
  }

  .refresh-button {
    flex-shrink: 0;
    padding: 0.5rem;
    font-size: 1.2rem;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .refresh-button .refresh-label {
    display: none;
  }

  .container {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .content {
    padding: 0.5rem 0;
  }

  .data-table {
    min-width: unset; /* let it flex naturally */
    width: 100%;
  }

  .table-container {
    padding: 0;
  }

}

